/* You can add global styles to this file, and also import other style files */

@import "../src/assets/fonts/font.css";
@import url(//fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Sharp|Material+Icons+Round|Material+Icons+Two+Tone);
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css';
.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.modal-open {
    overflow: scroll;
}

.modal-dialog {
    justify-content: center;
}

.color-change {
    color: #04A6BC;
}
.custom-text-time-color{
  color: #597578 !important;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    height: 90%;
    margin: 0;
}

body {
    background-color: #E5E5E5 !important;
}

@media(min-width: 400px) {
    html {
        font-size: 14px
    }
}

.modal-footer {
    place-content: center;
}

.modal {
    padding: 1rem;
}

.icon-image-preview {
    display: inline-block;
    font-size: 48px;
    width: 48px;
    overflow: hidden;
}

.myCustomModalClass .modal-content {
    max-width: 275.45px !important;
    //max-height: 314.81px !important;
    border-radius: 10px !important;
}

.contact-info-modal .modal-content {
    max-width: 60% !important;
}

@media screen and (max-width: 980px) {
    .contact-info-modal .modal-content {
        max-width: 100% !important;
    }
}

.modal-content {
    // max-width: 275.45px !important;
    border-radius: 10px !important;
    border: none !important;
}

.loader-position {
    position: fixed;
    bottom: 63px;
    left: calc(50% - 10rem);
    z-index: 9;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #32a6bc;
    color: #32a6bc;
    box-shadow: 9999px 0 0 -5px #32a6bc;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #32a6bc;
    color: #32a6bc;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #32a6bc;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #32a6bc;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #32a6bc;
    }
    30% {
        box-shadow: 9984px 0 0 2px #32a6bc;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #32a6bc;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #32a6bc;
    }
    30% {
        box-shadow: 9999px 0 0 2px #32a6bc;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #32a6bc;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #32a6bc;
    }
    30% {
        box-shadow: 10014px 0 0 2px #32a6bc;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #32a6bc;
    }
}
