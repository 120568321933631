@font-face {
    font-family: "SourceSansPro-Regular";
    src: local("SourceSansPro-Regular"), url(./SourceSansPro-Regular.ttf);
    src: local("SourceSansPro-Regular"), url(./SourceSansPro-Regular.ttf)format('woff');
    src: local("SourceSansPro-Regular"), url(./SourceSansPro-Regular.ttf)format('woff2');
}

@font-face {
    font-family: "FontsFree-Net-SFProText-Bold";
    src: local("FontsFree-Net-SFProText-Bold"), url(./FontsFree-Net-SFProText-Bold.ttf);
    src: local("FontsFree-Net-SFProText-Bold"), url(./FontsFree-Net-SFProText-Bold.ttf)format('woff');
    src: local("FontsFree-Net-SFProText-Bold"), url(./FontsFree-Net-SFProText-Bold.ttf)format('woff2');
}

@font-face {
    font-family: "FontsFree-Net-SFProText-Regular";
    src: local("FontsFree-Net-SFProText-Regular"), url(./FontsFree-Net-SFProText-Regular.ttf);
    src: local("FontsFree-Net-SFProText-Regular"), url(./FontsFree-Net-SFProText-Regular.ttf)format('woff');
    src: local("FontsFree-Net-SFProText-Regular"), url(./FontsFree-Net-SFProText-Regular.ttf)format('woff2');
}

@font-face {
    font-family: "FontsFree-Net-SFProText-Semibold";
    src: local("FontsFree-Net-SFProText-Semibold"), url(./FontsFree-Net-SFProText-Semibold.ttf);
    src: local("FontsFree-Net-SFProText-Semibold"), url(./FontsFree-Net-SFProText-Semibold.ttf)format('woff');
    src: local("FontsFree-Net-SFProText-Semibold"), url(./FontsFree-Net-SFProText-Semibold.ttf)format('woff2');
}

@font-face {
    font-family: "OpenSans-Regular";
    src: local("OpenSans-Regular"), url(./OpenSans-Regular.ttf);
    src: local("OpenSans-Regular"), url(./OpenSans-Regular.ttf)format('woff');
    src: local("OpenSans-Regular"), url(./OpenSans-Regular.ttf)format('woff2');
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src: local("OpenSans-SemiBold"), url(./OpenSans-SemiBold.ttf);
    src: local("OpenSans-SemiBold"), url(./OpenSans-SemiBold.ttf)format('woff');
    src: local("OpenSans-SemiBold"), url(./OpenSans-SemiBold.ttf)format('woff2');
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"), url(./Montserrat-Bold.ttf);
    src: local("Montserrat-Bold"), url(./Montserrat-Bold.ttf)format('woff');
    src: local("Montserrat-Bold"), url(./Montserrat-Bold.ttf)format('woff2');
}

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf);
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf)format('woff');
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf)format('woff2');
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: local("Montserrat-SemiBold"), url(./Montserrat-SemiBold.ttf);
    src: local("Montserrat-SemiBold"), url(./Montserrat-SemiBold.ttf)format('woff');
    src: local("Montserrat-SemiBold"), url(./Montserrat-SemiBold.ttf)format('woff2');
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"), url(./Montserrat-Medium.ttf);
    src: local("Montserrat-Medium"), url(./Montserrat-Medium.ttf)format('woff');
    src: local("Montserrat-Medium"), url(./Montserrat-Medium.ttf)format('woff2');
}

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf);
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf)format('woff');
    src: local("Montserrat-Regular"), url(./Montserrat-Regular.ttf)format('woff2');
}

@font-face {
    font-family: "Montserrat-Black";
    src: local("Montserrat-Black"), url(./Montserrat-Black.ttf);
    src: local("Montserrat-Black"), url(./Montserrat-Black.ttf)format('woff');
    src: local("Montserrat-Black"), url(./Montserrat-Black.ttf)format('woff2');
}

@font-face {
    font-family: "SF-Pro";
    src: local("SF-Pro"), url(./SFProDisplay-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "OpenSans-Bold";
    src: local("OpenSans-Bold"), url(./OpenSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "OpenSans-Regular";
    src: local("OpenSans-Regular"), url(./OpenSans-Regular.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
